//import ABI from './edubukABI.json'
import ABI from './edubuk-skalemainnetABI.json'
//XDC test network by aproova sir
//export const EdubukConAdd = "0x3F45093e022046E7dC2a81092122e7848Fc1Bf36";
//export const EdubukConAdd = "0x289E1792DF8791415af9c4999FdA5De10C531AEd";
//--------------Mainnet configuration---------------//
//0.8.22 compiler version used
//skale calyspo hub mainnet 
export const EdubukConAdd = "0xfcF386Fb19631248177c90A0e09060E0A2d6157a";
export const EdubukConABI = ABI.abi;
